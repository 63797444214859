import React  from 'react';

import * as marked from 'marked';

class ReactMarkdown extends React.Component {
    constructor() {
      super();
      this.state = { html: '' };
    }
  
    componentDidMount() {
      fetch(this.props.md)
        .then(response => response.text())
        .then(markdown => this.setState({ html: marked(markdown) }));
    }
  
    render() {
      return (
        <div dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
      );
    }
  }

class Home extends React.Component {
    render() {
        return (
            <ReactMarkdown md="./home.md"/>
        )
    }
  }

export default Home;