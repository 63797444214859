import React, {Component}  from 'react';

import "@progress/kendo-theme-default/dist/all.css";

import { Chat } from "@progress/kendo-react-conversational-ui";
import { IntlProvider, load } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/ko/numbers.json';
import caGregorian from 'cldr-dates-full/main/ko/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/ko/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/ko/timeZoneNames.json';

import axios from 'axios';
import * as marked from 'marked';

import ScrollToBottom from 'react-scroll-to-bottom';

load(
    likelySubtags,
    currencyData,
    weekData, numbers,
    caGregorian,
    dateFields,
    timeZoneNames
);

function MessageTemplate(props) {
    let message = props.item;
    let parser = marked.setOptions({});
    let parsedMessage = parser.parse(message.text);
    let htmlToinsert = { __html: parsedMessage };
    return (
        <div className="k-bubble">
            <div dangerouslySetInnerHTML={htmlToinsert} />
        </div>
    );
}

class Chatbot extends Component {
    constructor(props) {
        super(props);
        this.user = {
            id: 1,
            avatarUrl: "https://img.icons8.com/ultraviolet/344/human-head.png"
        };
        this.bot = { id: 0 };
        this.state = {
            messages: [
                {
                    author: this.bot,
                    suggestedActions: [
                        {
                            type: 'reply',
                            value: '서울시 확진자 수는?'
                        }, {
                            type: 'reply',
                            value: '브라질 확진자 수는?'
                        }, {
                            type: 'reply',
                            value: '코로나 치료제는?'
                        }, {
                            type: 'reply',
                            value: '고등학교 등교 안하나'
                        }
                    ],
                    timestamp: new Date(),
                    text: "안녕하세요, HIAI MRC 봇 1.0 입니다.\n\r코로나-19에 관해 물어보세요.",
                    // text: '[북한 "코로나로 다른나라 파국적 상황...](http://naver.com) \n\r 북한, <mark>코로나19</mark> 소강 속 단계적 개학…고3·대학생 등교 시작(서울=연합뉴스) 북한 대외선전매체 내나라는...'
                }
            ]
        };
    }

    typing = () => {
        let botResponce = Object.assign({}, null);
        botResponce.author = this.bot;
        botResponce.typing = true;
        return botResponce;
    };

    answer_bert = (result, event) => {
        let botResponce = Object.assign({}, event.message);
        botResponce.text = result.data['output'][2]['answer'];
        botResponce.author = this.bot;
        this.setState(prevState => ({
            messages: [
                ...prevState.messages,
                botResponce
            ]
        }));
    };

    answer_card = (result, event) => {
        let botResponce = Object.assign({}, event.message);
        botResponce.text = result.data['cards'][0][0] + ' \n\r ' + result.data['cards'][0][1];
        botResponce.author = this.bot;
        this.setState(prevState => ({
            messages: [
                ...prevState.messages,
                botResponce
            ]
        }));
    };

    answering = (event) => {
        this.setState((prevState) => ({
            messages: [
                ...prevState.messages,
                this.typing()
            ]
        }));
    }

    addNewMessage = (event) => {
        console.log(this.state);
        this.setState((prevState) => ({
            messages: [
                ...prevState.messages,
                event.message
            ]
        }));
        this.answering(event)
        console.log(event.message)
        let form = new FormData()
        form.append('query', event.message.text)
        console.log(form)
        axios.post('http://demo.tmkor.com:36210/logic_mrc/mrc/',form)
            .then (
                (result) => {
                    // console.log(result.data)
                    // let botResponce = Object.assign({}, event.message);
                    // // botResponce.text = this.countReplayLength(event.message.text);
                    // console.log(result.data['output'][2]['score'])
                    if(result.data['output'][2]['score'] > 0.95) {
                        this.answer_bert(result, event)
                        this.answering(event)
                        setTimeout(() => {
                            this.answer_card(result, event);
                        }, 1000);
                    } else {
                        this.answer_card(result, event);
                    }
                    // botResponce.author = this.bot;
                    // this.setState(prevState => ({
                    //     messages: [
                    //         ...prevState.messages,
                    //         botResponce
                    //     ]
                    // }));
                })
            .catch((error) => {
                console.log(error);
                let botResponce = Object.assign({}, event.message);
                // botResponce.text = this.countReplayLength(event.message.text);
                botResponce.text = "잘 모르겠어요. 질문 잘하는 사람이 뇌섹남이래요.";
                botResponce.author = this.bot;
                setTimeout(() => {
                    this.setState(prevState => ({
                        messages: [
                            ...prevState.messages,
                            botResponce
                        ]
                    }));
                }, 1000);
            })
        // setTimeout(() => {
        //     this.setState(prevState => ({
        //         messages: [
        //             ...prevState.messages,
        //             botResponce
        //         ]
        //     }));
        // }, 1000);
    };

    countReplayLength = (question) => {
        let length = question.length;
        let answer = question + " contains exactly " + length + " symbols.";
        return answer;
    }

    render() {
    return (
        <IntlProvider locale="ko">
            <ScrollToBottom>
                <Chat user={this.user}
                        messages={this.state.messages}
                        onMessageSend={this.addNewMessage}
                        placeholder={"여기에 입력해주세요."} width={400}
                        messageTemplate={MessageTemplate}></Chat>
            </ScrollToBottom>
        </IntlProvider>
    )};
};

export default Chatbot;