// react
import React, { Component } from 'react';
import update from 'immutability-helper';

// 3rd-pary
import { Select, Rate, Row, Col, Input, List, Divider, Typography, Button, Skeleton } from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';

const { Option } = Select;
const { Search } = Input;
const { Paragraph, Text } = Typography;

class Ctrl extends Component {
    onCatChange = (value) => {
        console.log("category is " + value);
        this.setState( prevState => ({
            category:value
        }));
    };

    onScoreChange = (value) => {
        if(value === 0) {
            value = undefined;
        }
        console.log("score is " + value);
        this.setState( prevState => ({
            score: value
        }));
    };

    onSearch = (value) => {
        console.log("keyword is " + value);
        this.setState( prevState => ({
            keyword:value
        }), () => {
            this.run();
        });
    };

    run = () => {
        const query = this.makeQuery();
            if(query !== false) {
                this.postQuery(query);
            }
    }

    makeQuery = () => {
        var score = undefined;
        if(this.state.score !== undefined) {
            score = "SCORE" + this.state.score
        }
        var startQuery = [this.state.category, score, this.state.keyword].filter(Boolean).join(" ");
        if(startQuery === "") {
            return false;
        }
        var query = [startQuery].concat(this.state.sentences)
        return query
    }

    postQuery = (query) => {
        console.log(query)
        console.log(JSON.stringify(query))
        let form = new FormData()
        form.append('query', JSON.stringify(query))
        console.log(form)
        this.setState( prevState => ({ 
            isloading:true,
            sentences: [
                ...prevState.sentences,
                ""
            ]
         }), () => {
            axios.post('http://demo.tmkor.com:9081/generate/',form)
            .then (
                (result) => {
                    console.log(result)
                    this.updateSentence(result.data['output']);
                    this.setState( prevState => ({ created:true, isloading:false, }));                    
                })
            .catch((error) => {
                console.log(error);
            })
        })
    };
        

    updateSentence = (sentence) => {
        const index = this.state.sentences.length - 1;
        this.setState(prevState => ({
            sentences: update(this.state.sentences, {[index]:{$set:sentence}})
        }));
    }
    

    constructor(props) {
        super(props);
        this.state = {
            isloading:false,
            category: 'CAT스킨케어',
            score:5,
            sentences: [
            ]
        }
    }
    render() {
        const { initLoading, isloading, created } = this.state;
        const loadMore =
            !initLoading && !isloading && created ? (
                <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 48,
                    lineHeight: '32px',
                }}
                >
                <Button type="primary" size="large" onClick={this.run}>다음 문장 만들기</Button>
                </div>
            ) : null;

        return (
            <>
                <Row>
                    <Col flex="300px">
                        카테고리 : 
                        <Select
                            style={{ width: 200 }}
                            placeholder="선택해 주십시오"
                            onChange={this.onCatChange}
                            defaultValue={this.state.category}
                        >
                            <Option value="CAT스킨케어">스킨케어</Option>
                            <Option value="CAT데일리뷰티">데일리뷰티</Option>
                            <Option value="CAT메이크업">메이크업</Option>
                            <Option value="CAT네일&향수">네일 & 향수</Option>
                            <Option value="CAT베이비">베이비</Option>
                            <Option value="CAT옴므">옴므</Option>
                            <Option value="CAT뷰티푸드&차">뷰티푸드 & 차</Option>
                            <Option value="CAT소품&도구">소품 & 도구</Option>
                        </Select>
                    </Col>
                    <Col flex="200px">
                        별점 : <Rate value={this.state.score} onChange={this.onScoreChange} />
                    </Col>
                    <Col flex="auto">
                        <Search
                            placeholder="힌트 단어 (예: 브랜드, 상품명, 시작 문장 등)"
                            enterButton="리뷰 만들기"
                            size="large"
                            onSearch={this.onSearch}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Paragraph><Text type="warning">주의</Text> : 학습 데이터 분포 상 별점이 낮을 수록 문장 생성이 부자연 스럽습니다.</Paragraph>
                    </Col>
                </Row>
                <Row>
                    <Col flex="auto">
                        <Divider orientation="left">Generated Review</Divider>
                        <List
                            header={<div>{this.state.hint}</div>}
                            footer={<div>문장 수 : {this.state.sentences.length} 글자 수 : {this.state.sentences.reduce((a,b) => a + b, "").length}</div>}
                            bordered
                            dataSource={this.state.sentences}
                            loadMore={loadMore}
                            renderItem={(item, index) => (
                                <List.Item>
                                    <Skeleton paragraph={false} loading={index === this.state.sentences.length -1 && isloading} style={{ width: '300px' }} active>
                                        <Paragraph editable={{onChange:(sentence) => {
                                            this.setState(prevState => ({
                                                sentences: update(this.state.sentences, {[index]:{$set:sentence}})
                                            }));
                                        }}}>{item}</Paragraph>
                                    </Skeleton>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default Ctrl;