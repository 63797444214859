// react
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, NavLink } from 'react-router-dom';

// 3rd-pary
import { Layout, Menu } from 'antd';
import { UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Elasticsearch, SearchBox, Facet, Results, MyCardItem } from 'react-elasticsearch'
import 'antd/dist/antd.css';

// framework
import './App.css';
import { MRC, dialo, Home, CTRL, MRCV2, MRCDemo, MRCV3, Stock38 } from './pages/index';

const { Header, Content, Footer, Sider } = Layout;

const menus = [
  { label: 'data', name: 'Data', icon: UserOutlined }
]

class App extends Component {
  render() {
    return (
      <Router>
        <Layout>
        <Header className="site-layout-background">
          <a href='http://virssist.co.kr/' target='_blank'><div className="logo" /></a>
        </Header>
        <Layout>
          <Sider theme="dark" breakpoint="lg" collapsedWidth="0">
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']}>
              <Menu.Item key="0">
                <NavLink to="/">
                  <UserOutlined />
                  <span className="nav-text">Home</span>
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item key="1">
                <NavLink to="/mrc">
                  <UserOutlined />
                  <span className="nav-text">MRC-demo</span>
                </NavLink>
              </Menu.Item> */}
              <Menu.Item key="4">
                <NavLink to="/mrcv2">
                  <UserOutlined />
                  <span className="nav-text">MRC-demo (1.1)</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="5">
                <NavLink to="/mrcv3">
                  <UserOutlined />
                  <span className="nav-text">MRC-demo (1.2)</span>
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item key="5">
                <NavLink to="/mrcdemo">
                  <UserOutlined />
                  <span className="nav-text">MRC-demo (cyberuniv)</span>
                </NavLink>
              </Menu.Item> */}
              <Menu.Item key="2">
                <UserOutlined />
                <NavLink to="/dialo">
                  <span className="nav-text">DialoGPT(KO)-demo</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3">
                <UserOutlined />
                <NavLink to="/ctrl">
                  <span className="nav-text">Ctrl-demo</span>
                </NavLink>
              </Menu.Item>
              <Menu.Item key="6">
                <NavLink to="/stock38">
                  <UserOutlined />
                  <span className="nav-text">공모주 시초/공모 가격 (%) 예측</span>
                </NavLink>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            <Content style={{ margin: '24px 16px 0' }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Route exact path="/" component={Home} />
                <Route exact path="/mrc" component={MRC} />
                <Route exact path="/mrcv2" component={MRCV2} />
                <Route exact path="/mrcv3" component={MRCV3} />
                <Route exact path="/mrcdemo" component={MRCDemo} />
                <Route exact path="/dialo" component={dialo} />
                <Route exact path="/ctrl" component={CTRL} />
                <Route exact path="/stock38" component={Stock38} />
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Virssist ©2020-2021</Footer>
          </Layout>
        </Layout>
        </Layout>
      </Router>
    );
  }
};

export default App;