import React, {Component}  from 'react';

import "@progress/kendo-theme-default/dist/all.css";

import { Chat } from "@progress/kendo-react-conversational-ui";
import { IntlProvider, load } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from 'cldr-numbers-full/main/ko/numbers.json';
import caGregorian from 'cldr-dates-full/main/ko/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/ko/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/ko/timeZoneNames.json';

import axios from 'axios';
import * as marked from 'marked';

import ScrollToBottom from 'react-scroll-to-bottom';

load(
    likelySubtags,
    currencyData,
    weekData, numbers,
    caGregorian,
    dateFields,
    timeZoneNames
);

function AttachmentTemplate(props) {
    let attachment = props.item;
    let parser = marked.setOptions({});
    let parsedMessage = parser.parse(attachment.content);
    let htmlToinsert = { __html: parsedMessage };
    if (attachment.contentType === 'markdown') {
        return (
            <div className="k-bubble">
                <div dangerouslySetInnerHTML={htmlToinsert} />
            </div>
        );
    } else if (attachment.contentType.match('^image/')) {
        return <img src={attachment.content} draggable={false} />;
    } else if (attachment.contentType === 'text/plain') {
        return attachment.content;
    } else {
        return null;
    }
}

function MessageTemplate(props) {
    let message = props.item;
    let parser = marked.setOptions({});
    let parsedMessage = parser.parse(message.text);
    let htmlToinsert = { __html: parsedMessage };
    return (
        <div className="k-bubble">
            <div dangerouslySetInnerHTML={htmlToinsert} />
        </div>
    );
}

class Chatbot extends Component {
    constructor(props) {
        super(props);
        this.user = {
            id: 1,
            avatarUrl: "https://img.icons8.com/ultraviolet/344/human-head.png"
        };
        this.bot = { id: 0 };
        this.state = {
            messages: [
                {
                    author: this.bot,
                    suggestedActions: [
                        {
                            type: 'reply',
                            value: '전문대 졸업자도 편입 가능한가요?'
                        }, {
                            type: 'reply',
                            value: '학자금 대출 가능한가요?'
                        }, {
                            type: 'reply',
                            value: '등록금이 얼마인지 궁금합니다 '
                        }
                    ],
                    timestamp: new Date(),
                    text: "OO사이버대학 입학상담 챗봇입니다. 🤖\n 궁금한 것을 물어보세요. 🔎"
                    // text: '[북한 "코로나로 다른나라 파국적 상황...](http://naver.com) \n\r 북한, <mark>코로나19</mark> 소강 속 단계적 개학…고3·대학생 등교 시작(서울=연합뉴스) 북한 대외선전매체 내나라는...'
                }
            ]
        };
    }

    typing = () => {
        let botResponce = Object.assign({}, null);
        botResponce.author = this.bot;
        botResponce.typing = true;
        return botResponce;
    };

    answer_bert = (result, event) => {
        let botResponce = Object.assign({}, event.message);
        botResponce.text = "제 생각은 " + result.data['output'][2]['answer'] + " 입니다.";
        botResponce.author = this.bot;
        this.setState(prevState => ({
            messages: [
                ...prevState.messages,
                botResponce
            ]
        }));
    };

    answer_card = (result, event) => {
        let botResponce = Object.assign({}, event.message);
        if(this.state.lastPage == 1) {
            botResponce.text = "여러가지 대답이 있어요.";
        } else {
            botResponce.text = "더 많은 대답을 생각해봤어요.";
        }
        botResponce.attachments = []
        result.data['cards'].forEach(card => {
            botResponce.attachments.push({contentType:'markdown', content: card[0] + ' \n\r ' + card[1]})
        });
        botResponce.author = this.bot;
        if(result.data['num_pages'] > this.state.lastPage) {
            botResponce.suggestedActions = [
                {
                    type: 'reply',
                    title: '더 알아보기',
                    value: '!__LOADMORE'
                }
            ];
        }
        this.setState(prevState => ({
            messages: [
                ...prevState.messages,
                botResponce
            ]
        }));
    };

    answering = (event) => {
        this.setState((prevState) => ({
            messages: [
                ...prevState.messages,
                this.typing()
            ]
        }));
    }

    addNewMessage = (event) => {
        console.log(event.message)

        let form = new FormData()
        form.append('port', 36214)
        if(event.message.text.startsWith('!__')) {
            console.log('special');
            form.append('query', this.state.lastQuery);
            form.append('page', this.state.lastPage + 1);
            this.setState((prevState) => ({
                lastPage : this.state.lastPage + 1
            }));
        } else {
            this.setState((prevState) => ({
                messages: [
                    ...prevState.messages,
                    event.message
                ],
                lastQuery : event.message.text,
                lastPage : 1
            }));
            this.answering(event)
            form.append('query', event.message.text)
        }
        
        console.log(this.state);
        
        console.log(form)
        axios.post('http://demo.tmkor.com:36216/logic_mrc_demo/mrc/',form)
            .then (
                (result) => {
                    console.log(result);
                    if(this.state.lastPage == 1 & result.data['output'][2]['score'] > 0.95) {
                        this.answer_bert(result, event)
                        this.answering(event)
                        setTimeout(() => {
                            this.answer_card(result, event);
                        }, 1000);
                    } else {
                        this.answer_card(result, event);
                    }
                })
            .catch((error) => {
                console.log(error);
                let botResponce = Object.assign({}, event.message);
                // botResponce.text = this.countReplayLength(event.message.text);
                botResponce.text = "잘 모르겠어요. 질문 잘하는 사람이 뇌섹남이래요.";
                botResponce.author = this.bot;
                setTimeout(() => {
                    this.setState(prevState => ({
                        messages: [
                            ...prevState.messages,
                            botResponce
                        ]
                    }));
                }, 1000);
            })
    };

    countReplayLength = (question) => {
        let length = question.length;
        let answer = question + " contains exactly " + length + " symbols.";
        return answer;
    }

    render() {
    return (
        <IntlProvider locale="ko">
            <ScrollToBottom>
                <Chat user={this.user}
                        messages={this.state.messages}
                        onMessageSend={this.addNewMessage}
                        placeholder={"여기에 입력해주세요."} width={400}
                        messageTemplate={MessageTemplate} attachmentTemplate={AttachmentTemplate}></Chat>
            </ScrollToBottom>
        </IntlProvider>
    )};
};

export default Chatbot;